<template>
  <router-view />
</template>

<script>
import { mapMutations } from 'vuex'
import { Trans } from '@/services/TranslationService'

export default {
  created () {
    const { newsroomSettings, storySettings, lang } = this.$route.query

    if (lang) {
      Trans.changeLanguage(lang)
    }

    this.setNewsroomSettings(newsroomSettings ? newsroomSettings.split(',') : [])
    this.setStorySettings(storySettings ? storySettings.split(',') : [])
  },

  beforeRouteUpdate (to, from, next) {
    window.parent.postMessage(JSON.stringify({
      action: 'navigation',
      data: {
        path: to.path,
        query: to.query
      }
    }), '*')
    next()
  },

  methods: {
    ...mapMutations({
      setNewsroomSettings: 'SET_NEWSROOM_SETTINGS',
      setStorySettings: 'SET_STORY_SETTINGS'
    })
  }
}
</script>
